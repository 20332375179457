import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { FaUserAlt, FaListAlt } from "react-icons/fa";
import { FiLogIn } from "react-icons/fi"
import { MdDeliveryDining, MdLogout } from "react-icons/md";
import { AiFillHeart, AiFillStar } from "react-icons/ai";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../Components/Loader/Loader'
import img from '../../Assets/Images/noimages.png';
import './rating.css'


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function RatingFunction() {
  let history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [logtoken, setLogtoken] = useState('')
  const [source, setSource] = useState([])
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = React.useState(false);



  let key1 = localStorage.getItem("ApiId")
  useEffect(() => {
    var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
    console.log('logged', logged)
    setLogtoken(logged)
    getData()
  }, [])

  const getData = () => {
    setLoading(true)
    var token = JSON.parse(localStorage.getItem("token"))
    fetch(global.url + "myOrdersfrontend", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129',
        'Authorization': 'Bearer ' + token
      },

    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false)
        if (responseJson.error == false) {
          setSource(responseJson.data)
        }
        else {
          setSource([])
        }
        console.log("order response", responseJson)

      })
      .catch((error) => {
        console.log(error)
      })
  }
  const logoutClick = () => {
    setLoader(true)
    var token = JSON.parse(localStorage.getItem("token"))
    fetch(global.url + "logout", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129',
        'Authorization': 'Bearer ' + token
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("logout", responseJson)
        setLoader(false)
        if (responseJson.error == false) {
          localStorage.setItem("isAuthenticatedfront", false);
          localStorage.removeItem("token")
          localStorage.removeItem("userName")
          localStorage.removeItem("persist:root")
          localStorage.removeItem("userdata")
          history.push('/')
          window.location.reload()

        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [selectedStarCount, setSelectedStarCount] = useState(0);
  const [selectedHoverStarCount, setSelectedHoverStarCount] = useState(0);
  const [ratingCount,setRatingCount]=useState(0)
  const ratingValue =[ '','Very Bad','bad','Good','Very Good','Excellent' ]


  return (
    <div>
      <div className='cartTop' style={{ fontFamily: "'Poppins', sans-serif" }}>
        <Container>
          <div>
            <p style={{ fontSize: 23, fontWeight: 500 }}>Rating & Reviewing</p>
          </div>
          <Row>
            <Col xs={12} md={4} lg={3}>
              <div className="profilebox">
                <a onClick={() => history.push('/profile')}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ padding: 15, paddingBottom: 1 }}>
                      <p style={{ fontSize: 14, cursor: 'pointer', fontWeight: 500 }}><FaUserAlt color="#000" size={13} style={{ marginRight: 7, marginTop: -2 }} /> My Profile</p>
                    </div>
                    <div style={{ height: 53, width: 5, backgroundColor: '#d9d9d9' }}></div>
                  </div>
                </a>
                <div style={{ width: '100%', height: 1, backgroundColor: '#d9d9d9' }}></div>
                <a>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ padding: 15, paddingBottom: 1 }}>
                      <p style={{ fontSize: 14, cursor: 'pointer', fontWeight: 500 }}><FaListAlt color="#000" size={15} style={{ marginRight: 5, marginTop: -2 }} /> My Orders</p>
                    </div>
                    <div style={{ height: 53, width: 5, backgroundColor: '#501033' }}></div>
                  </div>
                </a>
                <div style={{ width: '100%', height: 1, backgroundColor: '#d9d9d9' }}></div>
                <a onClick={() => history.push('/wishlists')}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ padding: 15, paddingBottom: 1 }}>
                      <p style={{ fontSize: 14, cursor: 'pointer', fontWeight: 500 }}><AiFillHeart color="#000" size={15} style={{ marginRight: 5, marginTop: -2 }} /> My Wishlist</p>
                    </div>
                    <div style={{ height: 53, width: 5, backgroundColor: '#d9d9d9' }}></div>
                  </div>
                </a>
                <div style={{ width: '100%', height: 1, backgroundColor: '#d9d9d9' }}></div>
                {logtoken ?
                  <a onClick={logoutClick}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ padding: 15, paddingBottom: 1 }}>
                        <p style={{ fontSize: 14, cursor: 'pointer', fontWeight: 500 }}><MdLogout color="#000" size={16} style={{ marginRight: 5, marginLeft: 3, marginTop: -2 }} /> Logout</p>
                      </div>
                      <div style={{ height: 53, width: 5, backgroundColor: '#d9d9d9' }}></div>
                    </div>
                  </a> :
                  <a onClick={() => history.push('/login')}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ padding: 15, paddingBottom: 1 }}>
                        <p style={{ fontSize: 14, cursor: 'pointer', fontWeight: 500 }}><FiLogIn color="#000" size={16} style={{ marginRight: 7, marginTop: -2 }} /> Login</p>
                      </div>
                      <div style={{ height: 53, width: 5, backgroundColor: '#d9d9d9' }}></div>
                    </div>
                  </a>
                }
              </div>
            </Col>

            <Col xs={12} md={8} lg={9} style={{ backgroundColor: '#fff' }}>
              {/* my orders */}

              <div>
                {loading ? <Loader /> :
                  <div>
                    {source.length == 0 ? <p style={{ fontSize: 14, fontWeight: 500, textAlign: "center", }}>No Orders</p> :
                      <div>
                        {source.map((item) => (
                          <a style={{ cursor: 'pointer' }}  >
                            <div className='rating-div'>

                              <Row style={{ border: ' 1px solid #b1abab66' }}>
                                <div style={{ padding: '20px 10px' }}>
                                  <h5>Rate this product</h5>
                                  <div className="star">
                                    {[...Array(5)].map((_, index) => {
                                      return (
                                        <span
                                          key={index}
                                          style={{fontSize:'30px',padding:'5px'}}
                                          className={`${index + 1 <= selectedStarCount ? "selected" : ""}${index + 1 <= selectedHoverStarCount ? "hovered" : ""}`}
                                          onMouseOver={()=>{
                                            setSelectedHoverStarCount(index+1)
                                          }}
                                          onMouseOut={() => {
                                            setSelectedHoverStarCount(0)
                                          }}
                                          onClick={() => {
                                            setSelectedStarCount(index + 1)
                                          }}
                                        >&#9733;</span>
                                      )
                                    })}
                                  </div>
                                  <div>
                                  {/* <span>{selectedStartCount ===0 ? null : ratingValue[selectedStartCount]}</span> */}
                                  </div>

                                </div>
                              </Row>
                              <Row style={{ border: ' 1px solid #b1abab66', borderTop: 'none' }}>
                                <div style={{ padding: '10px 10px 35px' }}>
                                  <h5 style={{ padding: '20px 0px' }}>Review this product</h5>
                                  <div style={{ border: ' 1px solid #b1abab66', margin: '0px 17px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label style={{ fontSize: '11px', padding: '12px 0px 0px 15px' }} >Description</label>
                                      <textarea name="description" rows={4} cols={40} placeholder="Description..." style={{ fontSize: '13px', border: 'none', outline: 'none', backgroundColor: 'transparent', overflowY: 'auto', height: '150px', padding: '8px 15px' }} />
                                    </div>
                                  </div>
                                  <div style={{ border: ' 1px solid #b1abab66', margin: '0px 17px', borderTop: 'none' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label style={{ fontSize: '11px', padding: '12px 0px 0px 15px' }} >Name</label>
                                      <input style={{ fontSize: '13px', border: 'none', outline: 'none', backgroundColor: 'transparent', padding: '8px 15px' }} placeholder="Name" />
                                    </div>

                                  </div>


                                </div>

                              </Row>
                            </div>
                          </a>
                        ))}
                      </div>}
                  </div>}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}
export default RatingFunction;