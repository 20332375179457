import React from 'react';
import './Footer.css';
import { Row, Col, Container } from "react-bootstrap";
import { MdOutlineSecurity, MdHeadsetMic } from "react-icons/md";
import { GiRoundStar } from "react-icons/gi";
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaYoutube, FaShoppingBag } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import youtube from '../../Assets/Images/youtube (1) 1.png';
import facebook from '../../Assets/Images/facebook (1) 1.png';
import whatsapp from '../../Assets/Images/whatsapp (1) 1.png';
import instagram from '../../Assets/Images/instagram 1.png';

function Footer() {

    return (
        <div style={{ marginTop: 70, backgroundColor: '#33001C' }} className='textfont'>
            <Container style={{ paddingTop: '12px' }}>
                <Row >
                    <Col xs={12} sm={6} md={2}>
                        {/* <p  ><MdOutlineSecurity color="#000" size={25}/></p> */}
                        <p className='footerhead1'  >Explore</p>
                        <p></p>
                        <p></p>
                        <p className='footerhead2'>Home</p>
                        <p className='footerhead2'  >Our Company</p>
                        <p className='footerhead2'  >Privacy Policy</p>
                        <p className='footerhead2'  >Carriers</p>

                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        {/* <p  ><MdHeadsetMic color="#000" size={25}/></p> */}
                        <p className='footerhead1'  >Services</p>
                        <p></p>
                        <p></p>
                        <p className='footerhead2'  >Automotive</p>
                        <p className='footerhead2'  >Body Parts</p>
                        <p className='footerhead2'  >Car Care</p>
                        <p className='footerhead2'  >Accessories</p>
                        <p className='footerhead2'  >Wrapping Tools</p>




                    </Col>
                    <Col xs={12} sm={6} md={2}>
                        {/* <p  ><GiRoundStar color="#000" size={25} /></p> */}
                        <p className='footerhead1'  >Follow Us</p>
                        <p></p>
                        <p></p>
                        <p className='footerhead2' style={{ cursor: 'pointer' }} ><img src={facebook} />  Facebook</p>
                        {/* <a style={{textDecoration:'none'}} href='https://www.instagram.com/skydunz_automotive?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='>  */}
                        <p className='footerhead2' style={{ cursor: 'pointer' }} > <img src={instagram} />  Instagram</p>
                        {/* </a> */}
                        <p className='footerhead2' style={{ cursor: 'pointer' }} ><img src={whatsapp} />  What's App</p>
                        <p className='footerhead2' style={{ cursor: 'pointer' }} ><img src={youtube} />  You Tube</p>


                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        {/* <p  ><FaShoppingBag color="#000" size={25} /></p> */}
                        <p className='footerhead1'  >Get In Touch   </p>
                        <p></p>
                        <p></p>
                        <p className='footerhead2'  >info@snapqatar.qa</p>
                        <a style={{textDecoration:'none'}} href='tel:+974%55555555'>
                            <p className='footerhead2'  >+974 555 55555</p>
                        </a>
                        <a style={{textDecoration:'none'}} href='tel:+974%0000000'>
                            <p className='footerhead2'  >+974 000 0000</p>
                        </a>



                    </Col>
                    <Col xs={12} sm={6} md={2}>
                        {/* <p  ><FaShoppingBag color="#000" size={25} /></p> */}
                        <p className='footerhead1'  >Snap Qatar</p>
                        <p></p>
                        <p></p>
                        {/* <p className='footerhead2'  >Bulding 69</p>
                        <p className='footerhead2'  >street 220,B-Ring Road</p>

                        <p className='footerhead2'  >Zone 26</p>
                        <p className='footerhead2'  >Doha,Qatar</p> */}
                        <p className='footerhead2'  >Salwa Road</p>
                        <p className='footerhead2'  >427,14,12</p>


                    </Col>
                </Row>
                {/* <div style={{width: '100%', backgroundColor: '#d2d2d2', height: 1}}></div> */}
                {/* <Row> */}
                {/* <Col xs={6} md={6} lg={3}>
                        <div style={{marginTop: 30}}>
                            <p style={{fontSize: 14, fontWeight: 'bold', color: '#fff'}}>COMPANY</p>
                            <div>
                                <li className="footer-li">Submit A Review</li>
                                <li className="footer-li">About Us</li>
                                <li className="footer-li">Press Release</li>
                                <li className="footer-li">See Map</li>
                            </div> 
                        </div>
                    </Col> */}
                {/* <Col xs={6} md={6} lg={4}>
                        <div style={{marginTop: 30}}>
                            <p style={{fontSize: 14, fontWeight: 'bold', color: '#fff'}}>MY ACCOUNT</p>
                            <div>
                                <ul style={{paddingLeft: 'unset'}}>
                                    <a href='/cart' style={{color: 'unset', textDecoration: 'unset'}}><li className="footer-li">My Account</li></a>
                                    <a href='/profile' style={{color: 'unset', textDecoration: 'unset'}}><li className="footer-li">Shopping Cart</li></a>
                                   
                                </ul>
                            </div> 
                        </div>
                    </Col> */}
                {/* <Col xs={6} md={6} lg={3}>
                    <div style={{marginTop: 30}}>
                            <p style={{fontSize: 14, fontWeight: 'bold', color: '#fff'}}>INFORMATION</p>
                            <div>
                                <li className="footer-li">Our Blog</li>
                                <li className="footer-li">Warranty & Return Policy</li>
                                <li className="footer-li">Privacy Policy</li>
                                <li className="footer-li">Term And Conditions</li>
                                <li className="footer-li">FAQ</li>
                                <li className="footer-li">Contact Us</li>
                            </div> 
                        </div>
                    </Col> */}
                {/* <Col xs={6} md={6} lg={4}>
                        <div style={{marginTop: 30}}>
                            <p style={{fontSize: 14, fontWeight: 'bold', color: '#fff'}}>HELP CENTER</p>
                            <p style={{fontSize: 13, color: '#fff'}}>Online.albadar.com</p>
                            <p style={{fontSize: 14, fontWeight: 'bold', color: '#fff'}}>EMAIL SUPPORT</p>
                            <p style={{fontSize: 13, color: '#fff'}}>info@albadar.com</p>
                            <p style={{fontSize: 14, fontWeight: 'bold', color: '#fff'}}>SUPPORT NUMBER</p>
                            <p style={{fontSize: 13, color: '#fff'}}>+974 31602060</p>
                        </div>
                    </Col> */}
                {/* <Col xs={12} md={12} lg={4} style={{marginTop: 30}}>
                        <p style={{fontSize: 14, fontWeight: 'bold', textAlign: 'center', color: '#fff'}}>CONNECT WITH SOCIAL MEDIA</p>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div>
                                <a href="https://www.facebook.com/" target="_blank"><FaFacebookF color="#fff" size={15} style={{}}/></a>
                                <a href="https://twitter.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJsYW5nIjoiZW4ifQ%3D%3D%22%7D"><AiOutlineTwitter color="#fff" size={18} style={{marginLeft: 20, marginTop: 2}}/></a>
                                <FaLinkedinIn href='https://in.linkedin.com/?src=go-pa&trk=sem-ga_campid.14650114788_asid.151761418307_crid.657403558721_kw.linkedin%20login_d.c_tid.kwd-12704335873_n.g_mt.e_geo.20461&mcid=6844056167778418689&cid=&gclid=EAIaIQobChMI96OB6IDSggMVqjWDAx3l_QVrEAAYASAAEgKrbPD_BwE&gclsrc=aw.ds' color="#fff" size={15} style={{marginLeft: 20}}/>
                                <a href="" target="https://www.instagram.com/accounts/login/"><FaInstagram color="#fff" size={15} style={{marginLeft: 20}}/></a>
                               
                            </div>
                        </div>
                    </Col> */}
                {/* </Row> */}

            </Container>
            <div className='copyright-div'
            // style={{backgroundColor:'#290017',width:'100%'}}
            >
                <p className="footer-copyright">Copyright © snapqatar</p>

            </div>
        </div>
    );
}

export default Footer;