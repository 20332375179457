import React, {useState, useEffect} from 'react';
import './Track.css';
import {Container, Row, Col, Button, Form } from "react-bootstrap";
import {useHistory, useParams} from 'react-router-dom';
import { FaUserAlt, FaListAlt } from "react-icons/fa";
import {FiLogIn} from "react-icons/fi"
import { MdDeliveryDining } from "react-icons/md";

import Header from '../../Components/Header/header';
import Footer from '../../Components/Footer/Footer';
import img from '../../Assets/Images/noimages.png';


function Track() {
    let history = useHistory();
    const {id} = useParams();
    const [source, setSource] = useState({})
    let key1 = localStorage.getItem("ApiId")
    useEffect(() => {
        getData()
    },[])

    const getData = () => {
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            orderid: id
        }
         fetch(global.url + "viewMyOrderfrontend", {
             method: "POST",
             headers: {
                 'Content-Type': 'application/json',
                 'APPKEY': 'erp6129',
                 'Authorization' : 'Bearer ' + token 
             },
             body: JSON.stringify(data)
         })
         .then((response)=> response.json())
         .then((responseJson)=>{
            console.log("responseJson", responseJson)
             setSource(responseJson.data)
         })
         .catch((error)=>{
             console.log(error)
         })
    }
    return(
        <div style={{fontFamily: "'lato', sans-serif"}}>
            <div style={{marginTop: 150, marginBottom: 30}}>
                <Container>
                    <div className='track-maindiv'>
                        <p style={{marginTop:200,fontSize: 23, fontWeight: 'bold'}}>Track Your Order</p>
                    </div>
                    <Row>
                        <Col xs={12} md={4} lg={3}>
                            <div className="profilebox">
                                <a onClick={() => history.push('/profile')}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div style={{padding: 15, paddingBottom: 1}}>
                                            <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><FaUserAlt color="#000" size={13} style={{marginRight: 7, marginTop: -2}} /> My Profile</p>
                                        </div>
                                        <div style={{height: 53, width: 5, backgroundColor: '#d9d9d9'}}></div>
                                    </div>
                                </a>
                                <div style={{width: '100%', height: 1, backgroundColor: '#d9d9d9'}}></div>
                                <a onClick={() => history.push('/order')}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div style={{padding: 15, paddingBottom: 1}}>
                                            <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><FaListAlt color="#000" size={15} style={{marginRight: 5, marginTop: -2}} /> My Orders</p>
                                        </div>
                                        <div style={{height: 53, width: 5, backgroundColor: '#501033'}}></div>
                                    </div>
                                </a>
                                {/* <div style={{width: '100%', height: 1, backgroundColor: '#d9d9d9'}}></div>
                                <a>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div style={{padding: 15, paddingBottom: 1}}>
                                            <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><MdDeliveryDining color="#000" size={20} style={{marginRight: 2, marginTop: -2, marginLeft: -1}} /> Track Order</p>
                                        </div>
                                        <div style={{height: 53, width: 5, backgroundColor: '#286bc7'}}></div>
                                    </div>
                                </a> */}
                                <div style={{width: '100%', height: 1, backgroundColor: '#d9d9d9'}}></div>
                                <a onClick={() => history.push('/login')}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div style={{padding: 15, paddingBottom: 1}}>
                                            <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><FiLogIn color="#000" size={16} style={{marginRight: 7, marginTop: -2}} /> Login</p>
                                        </div>
                                        <div style={{height: 53, width: 5, backgroundColor: '#d9d9d9'}}></div>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col xs={12} md={8} lg={9}>
                            <div class="card mb-3 trackdiv-respo">
                            <div class="p-4 text-center text-white text-lg rounded-top" style={{backgroundColor: '#501033'}}><span style={{fontSize: 13}} class="text-uppercase">Tracking Order No - </span><span style={{fontSize: 13}} class="text-medium">{source.invoice}</span></div>
                            <div className='mytrackdiv'>
                                <Row>
                                    <Col xs={4} sm={4} md={3} lg={3}>
                                    {
                                      source.image == null ? (
                                        <img alt="" src={img} className="order-img" style={{ width: '100%', height: 100 }} />
                                      ) : (
                                        (source.color == null && source.size == null) ? (
                                          <img alt="" src={global.images + key1+ "/combo_photos/" + source.image} className="order-img" style={{ width: '100%', height: 100 }} />
                                        ) : (
                                          <img alt="" src={global.images +key1+   "/ecommerce_photos/" + source.image} className="order-img" style={{ width: '100%', height: 100 }} />
                                        )
                                      )
                                    }
                                    </Col>
                                    <Col xs={12} md={9} lg={10}>
                                        <div>
                                            <p style={{marginBottom: 'unset', fontSize: 14, fontWeight: 500}}>{source.productname}</p>
                                            <p style={{fontSize: 12, fontWeight: 500, color: '#7a7a7a',marginBottom: 'unset'}}>Price : QAR {source.price}</p>
                                            <p style={{fontSize: 12, fontWeight: 500, color: '#7a7a7a',marginBottom: 'unset'}}>Color and Size : {source.color} - {source.size}</p>
                                            <p style={{fontSize: 12, fontWeight: 500, color: '#7a7a7a'}}>Quantity : {source.quantity}</p>
                                            {source.delivereddate == "" ? null :
                                            <p style={{fontSize: 14, fontWeight: 500, color: '#7a7a7a'}}>Delivered on {source.delivereddate}</p>
                                             }
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {source.cancelled == true ?
                            
                                <p style={{fontSize: 12, fontWeight: 500, color: '#7a7a7a'}}>{source.cancelled}</p>:
                                <div style={{paddingTop: 30}}>
                                    <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                                    <div class="step completed">
                                        <div class="step-icon-wrap">
                                        <div class="step-icon"><i class="pe-7s-cart"></i></div>
                                        </div>
                                        <h4  class="step-title">Ordered</h4>
                                    </div>
                                    <div class={source.shipped == true ? "step completed" : "step"}>
                                        <div class="step-icon-wrap">
                                        <div class="step-icon"><i class="pe-7s-config"></i></div>
                                        </div>
                                        <h4 class="step-title">Out For Delivery</h4>
                                    </div>
                                    <div class={source.delivered == true ? "step completed" : "step"}>
                                        <div class="step-icon-wrap">
                                        <div class="step-icon"><i class="pe-7s-car"></i></div>
                                        </div>
                                        <h4 class="step-title">Delivered</h4>
                                    </div>
                                    </div>
                                </div>
                            }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Track;