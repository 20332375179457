import React, {useState, useEffect} from 'react';
import './Wishlist.css'
import {Container, Row, Col, Button, Form } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import { FaUserAlt, FaListAlt } from "react-icons/fa";
import {FiLogIn} from "react-icons/fi"
import { MdDeliveryDining, MdLogout, MdDelete } from "react-icons/md";
import { AiFillHeart, AiFillStar } from "react-icons/ai";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../Components/Loader/Loader'
import {
    addToCart,
    refresh
  } from "../../redux/Shopping/shopping-actions";
  import { connect } from "react-redux";
import Header from '../../Components/Header/header';
import Footer from '../../Components/Footer/Footer';
import img from '../../Assets/Images/noimages.png';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function Wishlists({refresh}) {
    let history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState("");
    const [logtoken, setLogtoken] = useState('')
    const [source, setSource] = useState([])
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = React.useState(false);
    const [wishlist, setWishlist] = React.useState([]);
    let key1 = localStorage.getItem("ApiId")

    useEffect(() => {
        var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
        console.log('logged', logged)
        setLogtoken(logged)
        getWishList();
    },[])

    const getWishList = () => {
        setLoading(true)
        var token = JSON.parse(localStorage.getItem("token"))
         fetch(global.url + "viewMyWishLists", {
             method: "POST",
             headers: {
                 'Content-Type': 'application/json',
                 'APPKEY': 'erp6129',
                 'Authorization' : 'Bearer ' + token 
             },
         
         })
         .then((response)=> response.json())
         .then((responseJson)=>{
            console.log('responseJson', responseJson)
            setLoading(false)
             if(responseJson.error == false){
                setWishlist(responseJson.data);
                console.log(responseJson.data,"wishlist");
             }
             else{
                setWishlist([])
             }
             
         })
         .catch((error)=>{
             console.log(error)
         })
    }

    const deleteWishlist = (id) => {
        var token = JSON.parse(localStorage.getItem("token"))
        var data ={
            batch_id : id,
            mode: 'delete',
        }
         fetch(global.url + "AddtowishlistOrDelete", {
             method: "POST",
             headers: {
                 'Content-Type': 'application/json',
                 'APPKEY': 'erp6129',
                 'Authorization' : 'Bearer ' + token 
             },
             body: JSON.stringify(data)
         })
         .then((response)=> response.json())
         .then((responseJson)=>{
             if(responseJson.error == false){
                setOpen(true)
                setMessage(responseJson.message)
                getWishList();
             }
             else{
                setOpen(true)
                setMessage(responseJson.message)
                getWishList()
             }
            console.log("order cancel response", responseJson)
             
         })
         .catch((error)=>{
             console.log(error)
         })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

   
      const AddCart = (id) => {
        console.log("id", id)
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            "productid" : id,
            "quantity" : 1,
          
             
        }
        console.log("dataarr", data)
        fetch(global.url + "addCartfrontend", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
              'APPKEY': 'erp6129',
              'Authorization' : 'Bearer ' + token 
          },
          body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        
          if(responseJson.error == false){
            setOpen(true)
            setMessage(responseJson.message)  
            window.location.reload()
            refresh("") 
            
          }
          else{
            setOpen(true)
            setMessage(responseJson.message)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
      }

      const logoutClick = () =>{
        setLoader(true)
        var token =  JSON.parse(localStorage.getItem("token"))
        fetch(global.url + "logout", {
            method: "POST",
            headers: {
                'Content-Type' : 'application/json',
                'APPKEY' : 'erp6129',
                'Authorization' : 'Bearer ' + token 
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("logout", responseJson)
            setLoader(false)
            if(responseJson.error == false){
                localStorage.setItem("isAuthenticatedfront", false);
                localStorage.removeItem("token")
                localStorage.removeItem("userName")
                localStorage.removeItem("persist:root")
                localStorage.removeItem("userdata")
                history.push('/')
                window.location.reload()

            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return(
        <div>
            <div className='cartTop' style={{fontFamily: "'lato', sans-serif"}}>
                <Container>
                    <div>
                        <p style={{fontSize: 23, fontWeight: 500}}>My Wishlist</p>
                    </div>
                    <Row>
                        <Col xs={12} md={4} lg={3}>
                            <div className="profilebox">
                                <a onClick={() => history.push('/profile')}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div style={{padding: 15, paddingBottom: 1}}>
                                            <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><FaUserAlt color="#000" size={13} style={{marginRight: 7, marginTop: -2}} /> My Profile</p>
                                        </div>
                                        <div style={{height: 53, width: 5, backgroundColor: '#d9d9d9'}}></div>
                                    </div>
                                </a>
                                <div style={{width: '100%', height: 1, backgroundColor: '#d9d9d9'}}></div>
                                <a onClick={() => history.push('/order')}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div style={{padding: 15, paddingBottom: 1}}>
                                            <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><FaListAlt color="#000" size={15} style={{marginRight: 5, marginTop: -2}} /> My Orders</p>
                                        </div>
                                        <div style={{height: 53, width: 5, backgroundColor: '#d9d9d9'}}></div>
                                    </div>
                                </a>
                                <div style={{width: '100%', height: 1, backgroundColor: '#d9d9d9'}}></div>
                                <a >
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div style={{padding: 15, paddingBottom: 1}}>
                                            <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><AiFillHeart color="#000" size={15} style={{marginRight: 5, marginTop: -2}} /> My Wishlist</p>
                                        </div>
                                        <div style={{height: 53, width: 5, backgroundColor: '#501033'}}></div>
                                    </div>
                                </a>
                                <div style={{width: '100%', height: 1, backgroundColor: '#d9d9d9'}}></div>
                                {logtoken ? 
                                    <a onClick={logoutClick}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{padding: 15, paddingBottom: 1}}>
                                                <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><MdLogout color="#000" size={16} style={{marginRight: 5, marginLeft: 3, marginTop: -2}} /> Logout</p>
                                            </div>
                                            <div style={{height: 53, width: 5, backgroundColor: '#d9d9d9'}}></div>
                                        </div>
                                    </a>:
                                    <a onClick={() => history.push('/login')}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{padding: 15, paddingBottom: 1}}>
                                                <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><FiLogIn color="#000" size={16} style={{marginRight: 7, marginTop: -2}} /> Login</p>
                                            </div>
                                            <div style={{height: 53, width: 5, backgroundColor: '#d9d9d9'}}></div>
                                        </div>
                                    </a>
                                }
                            </div>
                        </Col>
                        <Col xs={12} md={8} lg={9}>
                        {loading ? <Loader /> :
                        <div>
                            {wishlist.length == 0 ? <p style={{fontSize: 14, fontWeight: 500,textAlign: "center", }}> No Products Added </p>
                            : <div>
                            {wishlist.map((item) =>(
                                    <div className='mywishlistsdiv'>
                                        <Row>
                                            <Col xs={12} md={4} lg={9}>
                                                <a style={{cursor: 'pointer'}} onClick={() => history.push('/details/' + item.products_id + '/' + item.pb_id  )}>
                                                    <Row>
                                                        {item.product_fetchiscombo == 1 ? 
                                                            <Col xs={12} lg={3}>
                                                                <img  src={ global.images +key1+  '/combo_photos/'+ item.product_comboimage} className='orderImg' style={{width: 100, height: 100,}} />
                                                            </Col>
                                                            : <Col xs={12} lg={3}>
                                                                { item.images.length == 0?
                                                                <img src={img}  className='orderImg' style={{width: 100, height: 100,}} />
                                                                :
                                                                <img src={ global.images +key1+  '/ecommerce_photos/'+ item.images[0].pei_image} className='orderImg' style={{width: 100, height: 100,}} />
                                                            }
                                                                {/* <img src={ global.images +  '/ecommerce_photos/'+ item.images[0].pei_image} className='orderImg' style={{width: 100, height: 100,}} /> */}
                                                            </Col>
                                                        }
                                                        {
                                                            item.product_fetchiscombo == 1?
                                                            <Col >
                                                             <div className='order-details'>
                                                                <p style={{fontSize: 14, fontWeight: 500}}>{item.product_name}</p>
                                                                {/* <p style={{fontSize: 13, fontWeight: 500}}>{item.color_name}, {item.size_name}</p> */}
                                                                <p style={{fontSize: 16, fontWeight: 500, color: '#000', marginTop: 10}}><span className='prd3-currency' >{item.currency}</span> {item.product_comboprice}</p>
                                                            </div>
                                                            </Col>:
                                                            <Col xs={12} lg={8}>
                                                            <div className='order-details'>
                                                                <p style={{fontSize: 14, fontWeight: 500}}>{item.product_name}</p>
                                                                <p style={{fontSize: 13, fontWeight: 500}}>{item.color_name}, {item.size_name}</p>
                                                                <p style={{fontSize: 16, fontWeight: 500, color: '#000', marginTop: 10}}><span className='prd3-currency'>{item.currency}</span> {item.pb_onlineprice}</p>
                                                            </div>
                                                        </Col>
                                                        }
                                                        
                                                    </Row>
                                                </a>
                                            </Col>
                                            <Col xs={12} lg={3}>
                                                <a style={{cursor: 'pointer'}}>
                                                    <div className='order-deliver' style={{display: 'flex', marginTop: 30}}>
                                                        <MdDelete color="#000" size={16} style={{marginTop: 2}} />
                                                        <p style={{marginBottom: 'unset', fontSize: 13, fontWeight: 500,}} onClick={(e) => deleteWishlist(item.wish_lists_productid)}>Remove</p>                                                     
                                                    </div>                                                   
                                                </a>
                                                <Button onClick={() => AddCart(item.pb_id)} style={{fontSize:12,fontWeight:600}} className=" btn11">Add To Cart</Button>
                                            </Col>
                                        </Row>
                                    </div>
                            ))}
                            </div>
                            }
                            </div>}
                        </Col>
                    </Row>
                </Container>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal:'center' }}
                open={open} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {message}
                    </Alert>
            </Snackbar>
        </div>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
      refresh: (item) => dispatch(refresh(item))
    };
  };
export default connect(mapDispatchToProps)(Wishlists);