import React from 'react';
import done from '../../Assets/gif/Animation - 1703329076058 (1).gif';
import {Container, Row, Col, Button, Form } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import logo from '../../Assets/Images/snap qatar logo  1.png';

function Summary() {
    let history = useHistory();
    return(
        <div style={{ marginBottom: 30}}>
             <div className="header2">
                <Container>
                    <Row>
                        <Col xs={5} lg={3}>
                            <div style={{marginTop:30 ,marginLeft:-30,marginBottom:15}}>
                                <a href="/" style={{cursor: 'pointer'}}>
                                    <div className='company-logo'
                                    // style={{height:'30%',width:'30%',paddingBottom:'5%'}} alt="" src={logo}  className='mainLogo'
                                    ></div> 
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 50}}>
                    <img src={done} style={{width: 200, height: 150}} />
                </div>
                {/* <p style={{textAlign: "center", fontSize: 20,  marginTop: 20 ,fontFamily: "'lato', sans-serif", fontWeight: 600}}>Your Order Confirmed !!!</p> */}
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                    <button onClick={() =>  history.push('/')} type="button" className='backtohome' >Back To Home</button>
                </div>
            </Container>
        </div>
    );
}

export default Summary;