import React, { useEffect, useState, useRef } from "react";

import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useHistory ,useParams} from "react-router-dom/cjs/react-router-dom.min";
import './subcategory.css'
import Loader from '../../Components/Loader/Loader'


function SubCategory() {

    const [subCatList, setSubCatList] = useState([]);
    const [loader, setLoader] = useState(false);
    const { categoryId } = useParams();
    
    let history = useHistory()
    let key1 = localStorage.getItem("ApiId")

    useEffect(() => {
        getSubcategorylist(categoryId)
        console.log(categoryId,"categoryId")
    }, [categoryId])

    const getSubcategorylist = () => {
        console.log("getSubcategorylist function is called")
        setLoader(true)

        var data = {
            "type": "admin",
            "companyid": 1
        }
        fetch(global.url + "viewSubcategory", {
            method: "POST",
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setLoader(false)
                if (responseJson.status == "false") {
                   
                    if (responseJson.data && Array.isArray(responseJson.data)) {
                        // console.log(responseJson,"sub category response json");
                        const filteredSubCategories = responseJson.data.filter(
                            (subcategory) => subcategory.subCategory_category == categoryId
                        );
                        setSubCatList(filteredSubCategories);
                        
                        // console.log(filteredSubCategories,"subcategory list")
                    }
                }
                else{
                    console.log("sub category else condition")
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }



    return (
        
        <div style={{marginBottom:'235px'}}>
            {loader ?
              <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
              <div style={{ marginTop: 200 }}>
                  <Loader />
              </div>
          </div>
        :
            <Container>
                <div className="catborders" >
                    <p style={{ color: '#501033', fontSize: 22, fontWeight: 600, marginBottom: 50, fontFamily: "'poppins',san-serif", textAlign: 'center',paddingTop:'14px' }}>SUB CATEGORIES</p>
                </div>
                <Row style={{justifyContent:'center'}}>
                    {subCatList.map((subcat) => (
                        <Col key={subcat.subCategory_id} xs={6} md={2} lg={2}>
                            <a onClick={()=>history.push('/product/'+subcat.subCategory_id +'/subcategory')}>
                                <div className="subcats">
                                    <img className='subcat-imgs' alt="subcategory" src={global.images  +key1+ "/ecommerce_photos/" + subcat.subCategory_image} />
                                </div>
                                <div>
                                    <p className='product-name' style={{ textAlign: 'center', fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 600, marginBottom: 15 }}>{subcat.subCategory_name}</p>
                                </div>
                            </a>
                        </Col>
                    ))}

                </Row>
            </Container>
}
        </div>
    )
}
export default SubCategory;