import React, { useEffect, useState, useRef } from "react";
import Loader from "../../Components/Loader/Loader";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import { FaSortDown } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import Product from "../../Components/NewArrival/product";

function AllCprd() {

    const [allPrd, setAllPrd] = useState([]);
    const [loading, setLoading] = useState(false);
    const containerRef = useRef(null);
    let history = useHistory();

    const comboDeliveryClick = (value) => {
        getComboProducts(value);
  
      };

    useEffect(() => {
        getComboProducts()
    }, [])

    const getComboProducts = (value) => {
        setLoading(true)
        var data = {
            deliverytime: value
        }
        fetch(global.url + "ProductComboDelivaryTimeFilter", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                APPKEY: "erp6129",
            },
            body: JSON.stringify(data)

        })
            .then((response) => response.json())
            .then((responseJson) => {
                setLoading(false)
                if (responseJson.error === false) {
                    setAllPrd(responseJson.data);
                    console.log(responseJson.data, "app prd resp");
                } else {
                    setAllPrd([])
                }

            }).catch((error) => {
                console.error(error)
            });
    }

    return (
        <div style={{backgroundColor:'#f3f3f3'}}>

            {loading ? <Loader /> :

                <div>
                    <Container>
                        <div style={{ marginTop: 20 }}>
                            {/* {arrival&&arrival.length == 0 ? null : ( */}
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingBottom: 10,
                                }}
                            >
                                {/* <div className='catborder'> */}
                                <p
                                    className="heading"
                                    style={{ fontFamily: "'lato', sans-serif" }}
                                >
                                    COMBO PRODUCTS
                                </p>
                                {/* </div> */}
                                {/* <h2 style={{color: '#000', fontSize: 28, fontWeight: 'bold'}}>New Arrival</h2> */}
                                <div
                                    className="dropdown"
                                    style={{ fontFamily: "'lato', sans-serif" }}
                                >
                                    <p
                                        className="dropbtn"
                                        style={{
                                            color: "#000000",
                                            fontSize: 20,
                                            fontWeight: 600,
                                            fontFamily: "'lato', sans-serif",
                                        }}
                                    >
                                        Delivery Time
                                        <FaSortDown style={{ marginTop: "-5px" }} />
                                    </p>
                                    <div className="dropdown-content">
                                        {/* <a value="">Link 1</a> */}

                                        <a onClick={() => comboDeliveryClick(24)}>24 hr delivery</a>
                                        <a onClick={() => comboDeliveryClick(48)}>48 hr delivery</a>
                                        <a className="deliveryclick-font" onClick={() => comboDeliveryClick(0)}>BACK</a>
                                    </div>
                                </div>
                            </div>
                            {/* )
                 } */}

                            <div>
                                {loading ? (
                                    <Row>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                    </Row>
                                ) : (
                                    // arrival.length <= (limit + 1) ?
                                    
                                    allPrd && allPrd.length > 0 ? (
                                          <Row>
                                            {allPrd.map((comboProduct) => (
                                              <Col
                                                key={comboProduct.products_id}
                                                xs={6}
                                                md={3}
                                                lg={2}
                                                className="spacereducer1"
                                                style={{ marginTop: 10}}
                                              >
                                                <a
                                                  onClick={() =>
                                                    history.push(
                                                      "/combodetails/" + comboProduct.products_id
                                                    )
                                                  }
                                                >
                                                  <div className="main-image-containers">
                                                    <div className="image-wrapper">
                                                      <img
                                                        alt=""
                                                        src={
                                                          global.images +
                                                          "/combo_photos/" +
                                                          comboProduct.product_comboimage
                                                        }
                                                        className="imagehome"
                                                      />
                                                    </div>
                                                    <div
                                                      className="textfont"
                                                      style={{
                                                        marginTop: 10,
                                                        position: "relative",
                                                      }}
                                                    >
                                                      <p className="product-name">
                                                        {comboProduct.product_name}
                                                      </p>
                                                      <p
                                                        className="price"
                                                        style={{ marginBottom: "unset" }}
                                                      >
                                                        QAR {comboProduct.product_comboprice}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </a>
                                              </Col>
                                            ))}
                                          </Row>
                                        ) : null
                                      
                                )
                                }
                            </div>

                        </div>
                    </Container>
                </div>
            }
        </div>
    )
}
export default AllCprd;