import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FaMobileAlt, FaListAlt } from "react-icons/fa";
import { FiSearch, FiLogIn } from "react-icons/fi";
import { MdDeliveryDining } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import { HiMenuAlt1 } from "react-icons/hi";
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import logo from "../../Assets/Images/FetchLogo.svg";
import cartimage from "../../Assets/Images/cart.svg";
import { useHistory } from "react-router-dom";
import profile from "../../Assets/Images/profile.svg";
import Loader from "../../Components/Loader/Loader";
import { BsArrowReturnRight } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import {
  FaShoppingCart,
  FaAddressBook,
  FaInfo,
  FaSignOutAlt,
} from "react-icons/fa";

import { connect } from "react-redux";

function Header(props) {
  let history = useHistory();
  const [cartCount, setCartCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [cartQty, setCartQty] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [logToken, setLogtoken] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const handleSearch = (event) => {
    const searchWord = event.target.value;
    console.log("searchWord", searchWord);
    setWordEntered(searchWord);
    var data = {
      search_product: searchWord,
      limit: 15,
    };
    fetch(global.url + "searchProducts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("search response : ", responseJson);
        if (responseJson.error == false) {
          setFilteredData(responseJson.data);
        } else {
          setFilteredData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };
  const listClick = (value) => {
    console.log("Clicked value:", value);
    
    setFilteredData([]);
    setWordEntered("");
    const productId = value.products_id;
    const pbid = value.pb_id;
    history.push(`/details/${productId}/${pbid}`);
    window.location.reload()
  };
  useEffect(() => {
    var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"));
    setLogtoken(logged);
  }, []);

  const logout = () => {
    setLoader(true);
    var token = JSON.parse(localStorage.getItem("token"));
    fetch(global.url + "logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("logout", responseJson);
        setLoader(false);
        if (responseJson.error == false) {
          localStorage.setItem("isAuthenticatedfront", false);
          localStorage.removeItem("token");
          localStorage.removeItem("userName");
          localStorage.removeItem("userdata");
          localStorage.removeItem("persist:root");

          history.push("/");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className={props.toggle ? "mobilemenu1" : "mobilemenu"}
      style={{ marginTop: 70, fontFamily: "'lato', sans-serif" }}
    >
      <div style={{ marginTop: 70, width: "90%" }} className="msearchbar">
        <div style={{ position: "relative" }}>
          <div>
            {/* <Form>
                                        <Form.Group className="mb-3 mt-4" >
                                            <Form.Control type="text" onChange={handleSearch} value={wordEntered} placeholder="What are you looking for?" 
                                             style={{borderColor: '#501033', fontWeight:500,fontSize:15, fontFamily: "'lato', sans-serif"}} />
                                        </Form.Group>
                                    </Form> */}
            <input
              // className="mb-3 mt-3"
              type="text"
              onChange={handleSearch}
              value={wordEntered}
              placeholder="search"
              style={{
                borderColor: "#501033",
                fontWeight: 400,
                fontSize: 13,
                fontFamily: "'lato', sans-serif",
                width: "100%",
                height: "35px",
                borderWidth: "1px",
                textIndent: "10px",
                color: "#000",
              }}
            ></input>
            {filteredData.length === 0 ? (
              <div
                style={{
                  backgroundColor: "#501033",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  padding: "4px",
                }}
              >
                {" "}
                <span style={{ textAlign: "center", padding: 5 }}>
                  <FiSearch color="#fff" size={25} />
                </span>{" "}
              </div>
            ) : (
              <AiOutlineClose
                onClick={clearInput}
                color="#000"
                size={17}
                style={{ position: "absolute", top: 12, right: 10 }}
              />
            )}
          </div>
          {filteredData.length == 0 ? null : (
            <div className="dataResult">
              {filteredData.map((value, key) => {
                return (
                  <a
                    key={key}
                    onClick={() => listClick(value)}
                    className="searchItem"
                    target="_blank"
                  >
                    <div style={{ marginTop: 10, display: "flex" }}>
                      <BsArrowReturnRight
                        color="#000"
                        size={14}
                        style={{ marginLeft: 20, marginTop: 2 }}
                      />
                      <p style={{ fontSize: 12, marginLeft: 10 }}>
                        {value.product_name}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: 1,
                        backgroundColor: "#d3d3d3",
                      }}
                    ></div>
                  </a>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <ul className="listtop">
        <li>
          <a href="/" style={{ color: "unset", textDecoration: "unset" }}>
            <AiFillHome className="menulistic" /> Home
          </a>
        </li>
        <li>
          <a href="/cart" style={{ color: "unset", textDecoration: "unset" }}>
            <FaShoppingCart className="menulistic" />
            My Cart
          </a>
        </li>
        <li>
          <a href="/order" style={{ color: "unset", textDecoration: "unset" }}>
            <FaListAlt className="menulistic" />
            My orders
          </a>
        </li>
        <li>
          <a
            href="/profile"
            style={{ color: "unset", textDecoration: "unset" }}
          >
            <FaAddressBook className="menulistic" /> Address
          </a>
        </li>
        <li>
          <a href="/" style={{ color: "unset", textDecoration: "unset" }}>
            <FaInfo className="menulistic" /> About Us
          </a>
        </li>

        {logToken ? (
          <li>
            {loader ? (
              <Loader />
            ) : (
              <a
                onClick={() => logout()}
                style={{ color: "unset", textDecoration: "unset" }}
              >
                <FaSignOutAlt className="menulistic" /> Logout
              </a>
            )}
          </li>
        ) : (
          <li>
            <a
              href="/login"
              style={{ color: "unset", textDecoration: "unset" }}
            >
              <FaSignOutAlt className="menulistic" /> Login
            </a>
          </li>
        )}
      </ul>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
    refreshitem: state.shop.refreshitem,
  };
};

export default connect(mapStateToProps)(Header);
