import './combodetails.css';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, FormControl } from "react-bootstrap";
import Dropdown from 'react-dropdown-select';
import { useForm, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Related from '../../Components/RelatedItems/RelatedItems';
import Skeleton from '@mui/material/Skeleton';
import ImageGallery from 'react-image-gallery';
import { useHistory, useParams } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineMinusSm } from "react-icons/hi";
import {
    addToCart, adjustItemQty, refresh
} from "../../redux/Shopping/shopping-actions";
import MuiAlert from '@mui/material/Alert';
import { connect } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import { FiCheck } from "react-icons/fi";
import img from '../../Assets/Images/noimages.png';
import Loader from '../../Components/Loader/Loader';

import { InputLabel, MenuItem, Select } from '@mui/material';
import Slider from "react-slick";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CProductDetails({ addToCart, cart, item, adjustQty, refresh }) {
    console.log(cart, "cart combodetails1")
    console.log(addToCart, "add to cart combo details1");
    let history = useHistory();
    const { id, combobatch } = useParams();

    const [open, setOpen] = React.useState(false);
    const [cartList, setCartList] = useState([])
    const [value, setValue] = React.useState('1');
    const [relatedLists, setRelatedlists] = useState([])
    const [sliderloading, setSliderloading] = useState(false);
    const [cartCount, setCartCount] = useState(0);
    const [brand, setBrand] = useState('');
    const [productName, setProductName] = useState('');
    const [modalCode, setModalCode] = useState('');
    const [sellingPrice, setSellingPrice] = useState('');
    const [actuallPrice, setActualPrice] = useState('');
    const [imageList, setImagelist] = useState([])
    const [img1, setImg1] = useState([]);
    const [productId, setProductId] = useState('');
    const [sizeLists, setSizelist] = useState([]);
    const [color, setColor] = useState();
    const [getColor, setGetcolor] = useState('');
    const [prodctId1, setProductId1] = useState('');
    const [batch1, setBatch1] = useState();
    const [loader, setLoader] = useState(false);
    const [batchRelate, setBatchRelate] = useState('');
    const [productColor, setProductColor] = useState('');
    const [input, setInput] = useState(1);
    const [stock, setStock] = useState('0');
    const [currency, setCurrency] = useState('');
    const [data1, setData1] = useState({});
    const [batchids, setBatchid] = useState();
    const [logtoken, setLogtoken] = useState('');
    const [cartView, setCartview] = React.useState(false)
    const [message, setMessage] = useState('');
    const [cartTotal, setCartTotal] = useState('');
    const [comboCartList, setComboCartList] = useState([]);

    const [details, setDetails] = useState([]);
    const [detail, setDetail] = useState([]);


    const [cartQty, setCartQty] = useState('');
    const [colorlist, setColorlist] = useState([]);
    const [total, setTotal] = useState(0);
    let key1 = localStorage.getItem("ApiId")
    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        // autoplay: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    // const [combobatch,setCombobatch] = useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
        setLogtoken(logged)
        getCartData()
    }, [])

    useEffect(() => {
        //    console.log("decodeURIComponent", atob(id, batch).replace("productid","batchid"));
        console.log("change")
        getData();
        getRelatedProduct();

    }, [batch1, prodctId1])

    useEffect(() => {
        setProductId1(id)
        setBatch1(combobatch)
        // console.log(combobatch, "combo batch")
    }, [id, combobatch])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const contHome = () => {
        history.push('/')
        setCartview(false)
    }
    const closeviewCart = () => {
        setCartview(false)
    }

    const viewCart = () => {
        setCartview(true)
    }



    const getData = () => {
        setLoader(true)
        if (logtoken == true) {
            var user = JSON.parse(localStorage.getItem("userdata"))
            console.log('user', user)
            var data = {
                'products_id': prodctId1,
                // 'batch_id': batch1,
                // 'user_id' : user.user_id
            }
            console.log("getDataLTST", data)
            fetch(global.url + "getProductbyFetchID", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129'
                },
                body: JSON.stringify(data)
            }).then((response) => response.json())
                .then((responseJson) => {
                    console.log('combo productdetails', responseJson);
                    if (responseJson.error == false) {
                        setLoader(false)
                        setDetails(responseJson.data.ProductCombo);
                        console.log(responseJson.data, "combo detail hhhhhhhhhhh")
                        setDetail(responseJson.data.ComboList);
                        // setProductId(responseJson.data.ProductCombo.product_description);

                        // const extractedProductIds = [];

                        responseJson.data.ComboList.forEach((combo) => {
                            // Access the products array
                            const products = combo.products;

                            if (Array.isArray(products) && products.length > 0) {
                                // Get the first product in the array and extract its pb_id
                                const firstProduct = products[0];
                                if (firstProduct && firstProduct.pb_id) {
                                    extractedProductIds.push(firstProduct.pb_id);
                                }
                            }
                        });

                        console.log('Extracted Product IDs:', extractedProductIds);


                        console.log('combo productdetails combolist ', responseJson.data.ComboList);

                        setSizelist(responseJson.data.size)
                        setColorlist(responseJson.data.colors)
                        if (responseJson.data.ProductCombo) {
                            setImagelist(responseJson.data.ProductCombo);
                            console.log(responseJson.data.ProductCombo, "combo image");
                        } else {
                            setImagelist([])
                        }
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else {
            var data = {
                'products_id': id,
                // 'batch_id': batch1,
            }
            console.log("getDataLTSTE", data)
            fetch(global.url + "getProductbyFetchID", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129'
                },
                body: JSON.stringify(data)
            }).then((response) => response.json())
                .then((responseJson) => {
                    console.log('combo productdetails', responseJson);
                    if (responseJson.error == false) {
                        setLoader(false)
                        setDetails(responseJson.data.ProductCombo);
                        setDetail(responseJson.data.ComboList);
                        // setProductId(responseJson.data.ProductCombo);
                        // console.log("combo descriptioooon",responseJson.data.ProductCombo.product_description);

                        const extractedProductIds = [];

                        responseJson.data.ComboList.forEach((combo) => {
                            // Access the products array
                            const products = combo.products;

                            if (Array.isArray(products) && products.length > 0) {
                                // Get the first product in the array and extract its pb_id
                                const firstProduct = products[0];
                                if (firstProduct && firstProduct.pb_id) {
                                    extractedProductIds.push(parseInt(firstProduct.pb_id, 10));
                                }
                            }
                        });

                        // console.log('Extracted Product IDs:', extractedProductIds);

                        // console.log('combo productdetails V54657656 ', responseJson.data.ProductCombo);
                        // console.log(responseJson.data, "66668668");
                        setSizelist(responseJson.data.size)
                        setColorlist(responseJson.data.colors)

                        if (responseJson.data.ProductCombo) {
                            setImagelist(responseJson.data.ProductCombo);
                        } else {
                            setImagelist([])
                        }
                    }


                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }

    const imageItems = details.map((product, index) => ({
        original: global.images + key1+ "/combo_photos/" + product.product_comboimage,
        thumbnail: global.images + key1+ "/combo_photos/" + product.product_comboimage,

    }));

    // const getCartData = () => {
    //     setLoader(true)

    //     // console.log("id is parsed : ", id)
    //     var token = JSON.parse(localStorage.getItem("token"))
    //     fetch(global.url + "displayCartfrontend", {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'APPKEY': 'erp6129',
    //             'Authorization': 'Bearer ' + token
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((responseJson) => {
    //             // console.log("responseJson cart12355", responseJson);
    //             if (responseJson.error == false) {

    //                 setLoader(false)
    //                 setCartList(responseJson.singleCart);
    //                 console.log(responseJson.singleCart, "single cart");
    //                 setComboCartList(responseJson.ComboCart);
    //                 console.log(responseJson.ComboCart, "combo cart");

    //                 console.log(responseJson.data, "cart data");
    //                 setCartQty(responseJson.cart_count)
    //                 setCartTotal(responseJson.total)
    //             } else {
    //                 setCartList([])
    //                 setComboCartList([])
    //                 setCartQty(0)
    //                 setCartTotal(0)
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // }

    const getCartData = async () => {
        try {
            setLoader(true);
    
            var token = JSON.parse(localStorage.getItem("token"));
    
            const response = await fetch(global.url + "displayCartfrontend", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129',
                    'Authorization': 'Bearer ' + token
                },
            });
    
            const responseJson = await response.json();
    
            if (responseJson.error === false) {
                setLoader(false);
                setCartList(responseJson.singleCart);
                console.log(responseJson.singleCart, "single cart");
                setComboCartList(responseJson.ComboCart);
                console.log(responseJson.ComboCart, "combo cart");
                console.log(responseJson.data, "cart data");
                setCartQty(responseJson.cart_count);
                setCartTotal(responseJson.total);
            } else {
                setCartList([]);
                setComboCartList([]);
                setCartQty(0);
                setCartTotal(0);
            }
        } catch (error) {
            console.log(error);
        }
    }
    

    const getRelatedProduct = () => {
        setSliderloading(true)
        var data = {
            "product_id": id,
            "batch_id": 1,
            "limit": 12
        }
        fetch(global.url + "viewRelatedProductsCombo", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
            .then((responseJson) => {
                console.log('related products :', responseJson)
                if (responseJson.error == false) {
                    setSliderloading(false)
                    setRelatedlists(responseJson.data)
                    console.log(responseJson.data, "combo related product")
                }
                else {
                    setSliderloading(false)
                    setRelatedlists([])
                }
            })
    }

    // const colorChange = (event) => {
    //     setBatch1(event.target.value);
    //     var data = {
    //         'batch_id': parseInt(event.target.value)
    //     }
    //     fetch(global.url + "viewProductbySize", {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'APPKEY': 'erp6129'
    //         },
    //         body: JSON.stringify(data)
    //     }).then((response) => response.json())
    //         .then((responseJson) => {

    //             if (responseJson.error == false) {
    //                 var data = responseJson.data[0];
    //                 var batch = data.batchid
    //                 setBatchid(batch)
    //                 setData1(data[0])
    //                 setBatch1(data.pb_id)
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // };

    const handleIncre = () => {
        if (input >= details.pb_stock) {
            console.log("ifinput", input)
        }
        else {
            setInput(prevCount => prevCount + 1)
        }
    }

    const handleDecre = () => {
        if (input > 1) {
            setInput(prevCount => prevCount - 1)
        }
    }

    const toClick = (data1) => {

        console.log("toClick function in combodetails1 is called")
        addToCart(data1)
        console.log(data1, "data1 combodetails1")
        adjustQty(batchids, input);
        setMessage("Added to Cart")
        setOpen(true)
        viewCart()
    }

    const AddCart = () => {
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            "productid": prodctId1,
            "quantity": input,
            "iscombo": 1,
            "compo_products": extractedProductIds
        }
        fetch(global.url + "addCartfrontend", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // alert(responseJson,"dfdfsd");
                if (responseJson.error == false) {
                    // alert(responseJson.error)
                    setOpen(true)
                    setMessage("Added To Cart")
                    refresh("")
                    viewCart()
                    getCartData()
                } else {
                    refresh("")
                    setOpen(true)
                    setMessage(responseJson.message)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    useEffect(() => {
        let count = 0;
        let singleproductprice = 0;
        let comboproductprice = 0
        let total = 0
        cart.forEach((item) => {
            const currentItem = Array.isArray(item.id) ? item.id[0] : item.id;
            console.log(currentItem, "current item")
            if (currentItem.product_fetchiscombo == 1) {
                comboproductprice += item.qty * currentItem.product_comboprice;
                console.log(currentItem.product_comboprice, "combo price")
            } else if (currentItem.pb_salesrate) {
                singleproductprice += item.qty * currentItem.pb_salesrate;
                console.log(singleproductprice, "single product price")

            }

            console.log(item, "cart foreach item")
            count += item.qty;

        });
        total = comboproductprice + singleproductprice
        console.log(total, "total price")
        setCartCount(count);
        setTotal(total);

    }, [cart, cartCount.toExponential]);


    const images = [
        {
            original: 'https://homestaymatch.com/images/no-image-available.png',
            thumbnail: 'https://homestaymatch.com/images/no-image-available.png',
        },
    ];

    const colorClick = (id) => {
        setBatch1(id)
    }

    const AddWishlist = () => {
        var token = JSON.parse(localStorage.getItem("token"))
        const data = {
            mode: 'add',
            batch_id: batch1,
        }

        fetch(global.url + 'AddtowishlistOrDelete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                window.location.reload()
                if (responseJson == false) {

                    setOpen(true)
                    setMessage("Added To Cart")
                }
                else {
                    setOpen(true)
                    setMessage(responseJson.message)
                }
            })


    }
    // const { control, handleSubmit } = useForm();
    // const [selectedOption, setSelectedOption] = useState(null);

    // const onSubmit = (data) => {
    //     console.log(selectedOption);
    //     console.log(data);
    // };


    return (
        <div>
            <Container>
                {loader ?
                    <Row>
                        <Col>
                            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                                <div style={{ marginTop: 150 }}>
                                    <Loader />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    :

                    <div>
                        <Row>
                            {details.map((product, index) => (
                                <Col >
                                    <div>
                                        <div key={index} className='cdets'>
                                            <ImageGallery items={imageItems} showPlayButton={false} />

                                            {/* <img src={global.images + "combo_photos/" + product.product_comboimage} alt={product.product_name} style={{ width: '100%', maxWidth: '100%', height: 'auto' }} /> */}
                                        </div>
                                        {/* mobileview */}
                                        <div key={index} className='cdetails1'>
                                            <ImageGallery items={imageItems} showPlayButton={false} />
                                            {/* <img src={global.images + "combo_photos/" + product.product_comboimage} alt={product.product_name} style={{ width: '100%', maxWidth: '100%', height: 'auto' }} /> */}
                                            <div style={{ paddingTop: 15 }}>
                                                <h2 className='p-name' >{product.product_name}</h2>
                                                <h4><span className="item-currency">QAR</span> {product.product_comboprice} </h4>
                                                <p className='p-code'>product code: {product.product_refcode}</p>
                                            </div>
                                        </div>
                                        {/* mobileview */}
                                    </div>
                                </Col>
                            ))}

                            <Col className='cdet' >
                                {details.map((product, index) => (
                                    <div key={index} className='cdetails'>
                                        <h3 className='p-name'>{product.product_name}</h3>
                                        <h4 style={{ fontFamily: "'lato', sans-serif", fontSize: '1.3rem', fontWeight: 600 }}  ><span className="item-currency">QAR </span> {product.product_comboprice} </h4>
                                        <p className='p-code'>Product code: {product.product_refcode}</p>
                                    </div>
                                ))}
                                <div class="outer-container">
                                    <div class="inner-container">
                                        <div class="element">
                                            {detail.map((cproduct, index) => (
                                                <div key={index} >
                                                    <Row className='rowhead'>
                                                        <Col>
                                                            {cproduct.products.length >= 1 && (
                                                                // <>
                                                                <div className='combocol'>
                                                                    <img
                                                                        src={global.images +key1+   "/ecommerce_photos/" + cproduct.products[0].pei_image}
                                                                        alt="product image"
                                                                        style={{ width: 150, height: 150 }}
                                                                    /></div>

                                                                // </>
                                                            )}
                                                        </Col>
                                                        <Col className='colhead' style={{ marginTop: 50, marginLeft: -250 }}>
                                                            {cproduct.products.length >= 1 && (
                                                                <>
                                                                    <h6 style={{ fontFamily: "'lato', sans-serif", fontWeight: 600 }} >{cproduct.product_name}</h6>
                                                                    <p style={{ fontFamily: "'lato', sans-serif" }}>Product Code: {cproduct.products[0].product_refcode}</p>
                                                                </>
                                                            )}

                                                            {/* <form onSubmit={handleSubmit(onSubmit)}>
                                                                <Controller
                                                                    name={`dropdown-${index}`}
                                                                    control={control}
                                                                    defaultValue={null}
                                                                    render={({ field }) => (
                                                                        <Dropdown
                                                                            options={cproduct.products.map(product => ({
                                                                                label: `${product.color_name} - ${product.size_name}`,
                                                                                value: product.size_id
                                                                            }))}
                                                                            onChange={(selected) => {
                                                                                field.onChange(selected[0])
                                                                                setSelectedOption(selected[0]);
                                                                            }}
                                                                            value={field.value}
                                                                            labelField="label"
                                                                            valueField="value"
                                                                            style={{
                                                                                maxWidth: '165px',
                                                                                menu: {
                                                                                    maxHeight: '200px',
                                                                                },
                                                                                dropdown: {
                                                                                    zIndex: 1000,
                                                                                },
                                                                            }}
                                                                        // style={{
                                                                        //     width: 'auto'
                                                                        //   }}
                                                                        //   autosize
                                                                        />
                                                                    )}
                                                                />
                                                            </form> */}
                                                        </Col>

                                                    </Row>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <Row>
                                    <Col xs={12} md={8} lg={4} style={{ marginTop: 20 }}>
                                        <Row>
                                            <Col xs={4} sm={2} md={4}>
                                                <p style={{ fontSize: 16, marginTop: 8, fontFamily: "'lato', sans-serif" }}>Qty</p>
                                            </Col>
                                            <Col xs={6} sm={5} md={8}>
                                                {loader ? <Skeleton sx={{ height: 30, marginTop: 1, width: '100%' }} animation="wave" variant="rectangular" /> :
                                                    <div>
                                                        {details.pb_stock == 0 ?
                                                            <p style={{ fontSize: 12, color: 'red', marginTop: 13, fontFamily: "'lato', sans-serif" }}>Out of Stock</p>
                                                            :
                                                            // <Form.Select aria-label="Default select example" style={{boxShadow: 'none'}}>
                                                            //     {options}
                                                            // </Form.Select>
                                                            <div style={{ marginTop: 12 }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div onClick={handleDecre} className='cart-decr'><HiOutlineMinusSm color="#000" size={15} /></div>
                                                                    <p style={{ marginBottom: 0, marginLeft: 10, marginRight: 10, fontSize: 14, marginTop: 2 }}>{input}</p>
                                                                    <div onClick={handleIncre} className='cart-decr'><BsPlus color="#000" size={15} /></div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={6} style={{ marginTop: 30 }}>
                                        {logtoken ?
                                            <Button onClick={() => AddCart()} variant="primary"  className="btn-success home-cartbtn buttonStyledel">Add To Cart</Button> :
                                            <Button onClick={() => toClick(details)} variant="primary" className="btn-success home-cartbtn buttonStyledel">Add To Cart</Button>
                                        }
                                    </Col>
                                    <Col xs={12} md={12} lg={6} style={{ marginTop: 20 }}>
                                        {logtoken ?
                                            <div>
                                                {details.wishlist == true ? null :
                                                    <Button  onClick={() => {
                                                        AddCart();
                                                        setTimeout(() => {
                                                            history.push("/checkout");
                                                        }, 2000);
                                                    }} className='wishbtn'>Buy Now</Button>}
                                            </div> :
                                            <Button  onClick={() => {toClick(details) ;
                                                 history.push('/login')}} className='wishbtn'>Buy Now</Button>
                                        }
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        <div>



                            <Row>
                                {/* <Col xs={12} md={12} lg={3} style={{ marginTop: 30 }}>
                                    {logtoken ?
                                        <Button onClick={() => AddCart()} variant="primary" style={{ boxShadow: 'none', width: '100%' }} className="btn-success home-cartbtn buttonStyledel">Add To Cart</Button> :
                                        <Button onClick={() => toClick(details)} variant="primary" style={{ boxShadow: 'none', width: '100%' }} className="btn-success home-cartbtn buttonStyledel">Add To Cart</Button>
                                    }
                                </Col>
                                <Col xs={12} md={12} lg={3} style={{ marginTop: 20 }}>
                                    {logtoken ?
                                        <div>
                                            {details.wishlist == true ? null :
                                                <Button onClick={AddWishlist} variant="#501033" className='wishbtn'>Add To Wishlist</Button>}
                                        </div> :
                                        <Button onClick={() => history.push('/login')} variant="#501033" className='wishbtn'>Add To Wishlist</Button>
                                    }
                                </Col> */}
                            </Row>
                        </div>
                        <div style={{ marginTop: 30 }}>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab style={{ fontFamily: "'lato', sans-serif", fontSize: '0.9rem', fontWeight: 500 }} label="Overview" value="1" />
                                            <Tab style={{ fontFamily: "'lato', sans-serif", fontSize: '0.9rem', fontWeight: 500 }} label="Description" value="2" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">

                                        <div>
                                            {/* <p style={{ fontSize: 14 }}>{productId}</p> */}
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <div>
                                            {/* <p style={{fontSize: 14}}>{productId}</p> */}
                                        </div>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                        <div style={{ marginTop: 30 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h2 style={{ color: '#000', fontSize: 28, fontFamily: "'lato', sans-serif", fontSize: 28, fontWeight: 500 }}>Related Products</h2>

                            </div>
                            <div>

                                {
                                    relatedLists.length == 0 ?
                                        <p>No Related Products</p> :

                                        <div style={{ fontFamily: "'lato', sans-serif" }}>
                                            <Row>
                                                {relatedLists.map((item, index) => {
                                                    console.log(item, "related item")
                                                    return (
                                                        item.product_comboimage == null ? null :
                                                            <Col xs={6} md={3} lg={3} style={{ marginTop: 10 }} className="spacereduce">
                                                                <a onClick={() => history.push('/combodetails/' + item.products_id)}>
                                                                    <div className='img-border'>
                                                                        <Slider {...settings} className="spclImg">
                                                                            {item.product_comboimage == null ?

                                                                                <img alt="" src={img} style={{ width: '100%', height: 180 }} />

                                                                                :

                                                                                // item.images.map((product) => {
                                                                                // return(
                                                                                <img alt="" src={global.images + key1+ "/combo_photos/" + item.product_comboimage} style={{ width: '100%', height: 150 }} />
                                                                                // )
                                                                                // })

                                                                            }
                                                                        </Slider>
                                                                        <div style={{ marginTop: 10, position: 'relative' }}>

                                                                            <p className='product-name' style={{ fontWeight: 600 }}>{item.product_name}</p>
                                                                            <p style={{ fontSize: 15, fontWeight: 600, marginBottom: 'unset' }}><span style={{ fontWeight: 600, color: '#501033', fontSize: 14 }}>QAR</span> {item.product_comboprice}</p>
                                                                            {/* <p className='arrival-para3'><span style={{textDecorationLine: 'line-through', color: '#5c5c5c'}}>QAR {item.pb_salesrate}</span></p> */}
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>



                                }
                            </div>
                        </div>
                    </div>
                }

                {/* cart pop */}

                {cartView ?
                    <div className="cart-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <p style={{ fontWeight: 'bold', fontSize: 16, marginTop: 1 }}>Cart</p>
                            </div>
                            <a onClick={closeviewCart}>
                                <AiOutlineClose color="#000" size={18} />
                            </a>
                        </div>
                        {logtoken ?
                            <div className='innerdiv'>
                                {cartList.length === 0 && comboCartList.length === 0 ? <p style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center', marginTop: 30 }}>No Cart Added</p> :
                                    <div>
                                        <div>
                                            {cartList.map((item, index) => (
                                                <div>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                {
                                                                    item.image == null ?
                                                                        <img alt="fetch" src={img} style={{ width: 80, height: 80 }} /> :
                                                                        <img alt="fetch" src={global.images + key1+ "/ecommerce_photos/" + item.image} style={{ width: 80, height: 80 }} />
                                                                }

                                                            </div>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <div>
                                                                <p className='product-name' style={{ fontSize: 11 }}>{item.name}</p>
                                                                <p style={{ fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 'bold' }}>{item.quantity} x {item.price} {item.currency}</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{ backgroundColor: '#d3d3d3', width: '100%', height: 1 }}></div>
                                                </div>
                                            ))}
                                            {comboCartList.map((item, index) => (
                                                <div>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                {
                                                                    item.image == null ?
                                                                        <img alt="fetch" src={img} style={{ width: 80, height: 80 }} /> :
                                                                        <img alt="fetch" src={global.images + key1+ "/combo_photos/" + item.image} style={{ width: 80, height: 80 }} />
                                                                }

                                                            </div>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <div>
                                                                <p className='product-name' style={{ fontSize: 11 }}>{item.name}</p>
                                                                <p style={{ fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 'bold' }}>{item.quantity} x {item.price} {item.currency}</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{ backgroundColor: '#d3d3d3', width: '100%', height: 1 }}></div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            // null
                            <div className='innerdiv'>
                                {cart.length === 0 ? (
                                    <p style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center', marginTop: 30 }}>No Cart Added</p>
                                ) : (
                                    <div>
                                        {cart.map((item) => (
                                            Array.isArray(item.id) ? (
                                                <div key={item.id[0].products_id}>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                {
                                                                    item.id[0].product_comboimage == null ? (
                                                                        <img alt="" src={img} className="order-img" style={{ width: '100%', height: 100 }} />
                                                                    ) : (
                                                                        <img alt="" src={global.images + key1+ "/combo_photos/" + item.id[0].product_comboimage} className="order-img" style={{ width: '100%', height: 100 }} />
                                                                    )
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <div>
                                                                <p className='product-name' style={{ fontSize: 11 }}>{item.id[0].product_name}</p>

                                                                <p style={{ fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 'bold' }}>{item.qty} x {item.id[0].product_comboprice} <span>QAR</span></p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) : (
                                                <div key={item.id.products_id}>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                {
                                                                    item.id.images[0].length === 0 ? (
                                                                        <img alt="" src={img} className="order-img" style={{ width: '100%', height: 100 }} />
                                                                    ) : (
                                                                        <img alt="" src={global.images + key1+ "/ecommerce_photos/" + item.id.images[0].pei_image} className="order-img" style={{ width: '100%', height: 100 }} />
                                                                    )
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <div>
                                                                <p className='product-name' style={{ fontSize: 11 }}>{item.id.name}</p>
                                                                <p style={{ fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 'bold' }}>{item.qty} x {item.id.pb_salesrate} {item.id.currency}</p>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{ backgroundColor: '#d3d3d3', width: '100%', height: 1 }}></div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                )}
                            </div>

                        }
                        {logtoken ?
                            <div style={{ marginTop: 20 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ fontWeight: 'bold', fontSize: 14 }}>Sub total</p>
                                    <p style={{ fontWeight: 'bold', fontSize: 14 }}>QAR {cartTotal}</p>
                                </div>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Button onClick={() => history.push('/checkout')} variant="primary" style={{ fontSize: 12, fontWeight: 'bold', width: '100%', borderColor: '#501033', backgroundColor: '#501033', color: '#fff', marginTop: 10, boxShadow: 'none' }}>Checkout</Button>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Button onClick={() => contHome()} variant="light" style={{ fontSize: 12, fontWeight: 'bold', width: '100%', borderColor: '#501033', backgroundColor: 'none', color: '#501033', marginTop: 10, boxShadow: 'none' }}>Continue Shopping</Button>
                                    </Col>
                                </Row>
                            </div> :
                            <div style={{ marginTop: 20 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ fontWeight: 'bold', fontSize: 14 }}>Sub total</p>
                                    <p style={{ fontWeight: 'bold', fontSize: 14 }}>QAR {total}</p>
                                </div>
                                <Row>
                                    <Col xs={6}>
                                        <Button onClick={() => history.push('/login')} variant="primary" style={{ fontSize: 12, fontWeight: 'bold', width: '100%', borderColor: '#501033', backgroundColor: '#501033', color: '#fff', marginTop: 10, boxShadow: 'none' }}>Checkout</Button>
                                    </Col>
                                    <Col xs={6}>
                                        <Button onClick={() => contHome()} variant="light" style={{ fontSize: 12, fontWeight: 'bold', width: '100%', borderColor: '#501033', backgroundColor: 'none', color: '#501033', marginTop: 10, boxShadow: 'none' }}>Continue Shopping</Button>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div> : null
                }

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Container >
        </div >
    );
}

const mapDispatchToProps = (dispatch) => {
    console.log(dispatch, "dispatch 1")
    return {
        addToCart: (item) => {
            console.log(item, "dispatch add cart")
            dispatch(addToCart(item))
        },
        adjustQty: (id, value) => dispatch(adjustItemQty(id, value)),
        refresh: (item) => dispatch(refresh(item))
    };
};
const mapStateToProps = (state) => {
    return {
        cart: state.shop.cart,
    };
};
const uniqueProductIds = new Set();
export const extractedProductIds = [...uniqueProductIds];
export default connect(mapStateToProps, mapDispatchToProps)(CProductDetails);