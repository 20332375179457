// import React from 'react';
// import './Slider.css';
// import {Container, Row, Col, Button, Carousel } from "react-bootstrap";
// import banner1 from '../../Assets/Images/banner1.png';
// import {useHistory} from 'react-router-dom';

// function Banner (props) {
//     let history = useHistory();
//     return(
//         <div>
//             <Carousel>
//                     {
//                         props.data.map((item) => {
//                             return(

//                                     <Carousel.Item>
//                                          <a onClick={()=> history.push('/product/' + item.link_id + "/category") }>
//                                             <img
//                                             className="d-block w-100 banner-img"
//                                             src={global.images + "ecommerce_photos/" + item.image}
//                                             alt=""
//                                             style={{marginTop:39 ,width: '100%', height: '98%'}}
//                                             />
//                                         </a>
//                                         <div className="carousel-caption">
//                                         <h3>{item.title}</h3> {/* Display title */}
//                                         <p>{item.description}</p> {/* Display description */}
//                                         </div>
//                                     </Carousel.Item>

//                             )
//                         })
//                     }
//                 </Carousel>
//         </div>
//     );
// }

// export default Banner;

import React from 'react';
import './Slider.css';
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';


function Banner(props) {
  console.log(props, "props123455676788")
  let key1 = localStorage.getItem("ApiId")
  const history = useHistory();

  return (
    <div className='slider'>
      <Carousel>
        {props.data.map((item) => (
          <Carousel.Item key={item.id}>
            <div>
              <a
                // onClick={() => history.push('/product/' + item.link_id + '/subcategory')}
                onClick={() => history.push('/category/1')}
              >
                <img
                  className="d-block  banner-img"
                  src={global.images + key1+'/ecommerce_photos/' + item.image}
                // style={{ marginTop: 39, height: '100%' }}
                />
                {/* <div className="slide-content">
              <p className='title' >Nuts , Seeds ,  Dates:<br></br>A Trio of<br></br>Natural Goodness .</p>
            </div>   */}
              </a>
            </div>

          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Banner;